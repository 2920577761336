<template>
  <div class="product-images">
    <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.xs ? 12 : 3"
        :md="$vuetify.breakpoint.xs ? 12 : 3"
        class="d-flex flex-row flex-sm-column thumb-wrapper"
        v-if="images.length > 1"
        :order="$vuetify.breakpoint.xs ? 2 : 0"
      >
        <swiper :options="swiperOptionThumbs" class="swiper" ref="swiperThumb">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </swiper-slide>
        </swiper>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.xs ? 12 : 9"
        :md="$vuetify.breakpoint.xs ? 12 : 9"
        class="d-flex justify-center detail-img-wrapper"
      >
        <div class="image-block-wrapper">
          <img
            class="image-mobile"
            v-if="$vuetify.breakpoint.xs"
            :src="selectedImage.medium"
            :alt="'Immagine prodotto ' + name"
            @click.stop="dialog = true"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          />
          <zoom-on-hover
            v-else
            :scale="1.5"
            :img-normal="selectedImage.medium"
            :img-zoom="selectedImage.large"
          ></zoom-on-hover>
          <div class="zoom-icon-wrapper d-flex justify-end">
            <v-icon>$search</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="zoom-dialog"
    >
      <div class="zoom-dialog-content mt-4">
        <v-row no-gutters justify="center">
          <v-btn
            color="primary"
            large
            @click.stop="dialog = false"
            width="250"
            elevation="0"
            >{{ $t("common.close") }}</v-btn
          >
        </v-row>
        <pinch-zoom height="100%" class="mt-6">
          <img :src="selectedImage.large" />
        </pinch-zoom>
      </div>
      <div
        class="d-flex flex-row zoom-swiper-wrapper mt-6"
        v-if="images.length > 1"
      >
        <swiper :options="swiperOptionThumbs" class="swiper">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </swiper-slide>
        </swiper>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import PinchZoom from "vue-pinch-zoom";

export default {
  name: "ProductImages",
  props: {
    images: { type: Array, required: true },
    name: { type: String, required: false }
  },
  components: { PinchZoom },
  data() {
    return {
      selectedImage: {},
      dialog: false,
      swiperOptionThumbs: {
        slidesPerView: "auto",
        slidesPerGroup: "auto",
        watchOverflow: true,
        direction: this.$vuetify.breakpoint.xs ? "horizontal" : "vertical"
      },
      zoomSwiperOptionThumbs: {
        slidesPerView: 3,
        // slidesPerGroup: 3,
        spaceBetween: 20,
        watchOverflow: true
      }
    };
  },
  methods: {
    openZoom() {}
  },
  mounted() {
    this.selectedImage = this.images[0];
  }
};
</script>

<style scoped lang="scss">
.product-images {
  .swiper-slide {
    width: auto;
    height: auto;
  }
  .thumb-wrapper {
    margin: 15px 0;
    position: relative;
    .thumb-preview {
      width: 100px;
    }
    img {
      padding: 5px;
      width: 80px;
      height: 80px;
      border: 1px solid transparent;
      &.selected {
        border-color: #e6e6e6;
      }
    }
  }
  .image-block-wrapper {
    max-width: 324px !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .image-block-wrapper {
      position: relative;
      max-width: initial !important;
      display: flex;
      justify-content: center;
      .image-mobile {
        max-width: 80%;
      }
    }
  }
}
.zoom-icon-wrapper {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    margin-right: -25px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}
.zoom-dialog-content {
  .pinch-zoom-wrapper {
    background-color: $white !important;
    max-width: 95% !important;
    margin: 0 auto;
    img {
      border: 1px solid #e6e6e6;
    }
  }
}
.zoom-swiper-wrapper {
  .swiper-slide {
    img {
      padding: 5px;
      width: 80px;
      height: 80px;
      border: 1px solid transparent;
      &.selected {
        border-color: #e6e6e6;
      }
    }
  }
}
</style>
