<template>
  <div class="product-detail-view">
    <v-container>
      <div class="d-flex justify-space-between align-center">
        <Breadcrumb :items="breadcrumb" />
        <v-spacer />
        <back-button v-if="$vuetify.breakpoint.xs" @back="goBack" />
      </div>
    </v-container>

    <v-container>
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
      />
      <back-button v-if="!$vuetify.breakpoint.xs" class="mt-5" @back="goBack" />
    </v-container>
    <v-container>
      <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + key + '_' + product.productId"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
      <ProductListSlider
        v-if="product.variations && product.variations.length > 0"
        :productList="product.variations"
        :key="product.productId + '_3'"
        :paginationClass="'swiper-pagination-linea'"
        position="product_variations"
      >
        <h2>Altri prodotti della <strong>stessa linea:</strong></h2>
      </ProductListSlider>
    </v-container>
    <div class="grey lighten-1" v-show="layout20length > 0">
      <v-container>
        <ProductListSlider
          v-if="product.productId"
          :layout="220"
          :limit="12"
          :productId="product.productId"
          :key="product.productId + '_1'"
          :paginationClass="'swiper-pagination-interesse'"
          position="product_correlati"
          class="pb-10"
          @loaded="loaded"
        >
          <div class="text-h4 text-sm-h2 font-weight-regular mb-3 mb-sm-4">
            Ti potrebbe <strong>interessare?</strong>
          </div>
        </ProductListSlider>
      </v-container>
    </div>
    <v-container>
      <ProductListSlider
        v-if="product.productId"
        :layout="223"
        :limit="12"
        :productId="product.productId"
        :key="product.productId + '_2'"
        :paginationClass="'swiper-pagination-suggeriti'"
        position="product_suggeriti"
      >
        <div class="text-h4 text-sm-h2 font-weight-regular mb-3 mb-sm-4">
          Spesso acquistati <strong>insieme</strong>
        </div>
      </ProductListSlider>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.back-button {
  &:hover {
    opacity: 0.5;
  }
}
</style>
<script>
import join from "lodash/join";
import compact from "lodash/compact";
import get from "lodash/get";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
// import BackButton from "@/components/navigation/BackButton.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";
import BackButton from "@/components/navigation/BackButton.vue";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      layout20length: 0
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb,
    ProductListSlider,
    BackButton
    // ,
    // BackButton
  },
  computed: {
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    loaded(productsFound) {
      this.layout20length = productsFound;
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      await this.getProduct();
      this.key += 1;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    this.slug = this.$route.params.slug;
    this.showNoProductCard = false;
    await this.getProduct();
    analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        await this.getProduct();
        analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    return {
      title: get(
        this.product,
        "metaData.product_seo.SEO_TITLE",
        this.getFullName
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category.name +
              " in offerta e promozione su Dupliclick con consegna in azienda e ritiro in negozio."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.product,
            "metaData.product_seo.SEO_TITLE",
            this.getFullName
          )
        },
        {
          property: "og:type",
          content: "product"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content: get(
            this.product,
            "media[0].large",
            window.location.host + "/logo/social-logo.png"
          )
        },
        {
          property: "og:image:alt",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category.name +
              " in offerta e promozione su Dupliclick con consegna in azienda e ritiro in negozio."
          )
        },
        {
          property: "og:site_name",
          content: "DupliClick"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category.name +
              " in offerta e promozione su Dupliclick con consegna in azienda e ritiro in negozio."
          )
        },
        {
          name: "twitter:image",
          content: get(
            this.product,
            "media[0].large",
            window.location.host + "/logo/social-logo.png"
          )
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
